import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class AuthentificationService {
  constructor(private http: HttpClient) {}

  public token: BehaviorSubject<string> = new BehaviorSubject<string>("");

  login() {
    let url: string =
      "https://eu.battle.net/oauth/token?grant_type=client_credentials";

    let data = {
      grant_type: "client_credentials"
    };

    this.http
      .post<any>(url, data, {
        headers: {
          Authorization:
            "Basic " + btoa(environment.clientId + ":" + environment.secret),
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*"
        }
      })
      .subscribe(resp => {
        this.token.next(resp.access_token);
        //environment.token = resp.access_token;
      });
  }

  getToken(): Observable<any> {
    return this.token.asObservable();
  }
}
