import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private currentLangage: BehaviorSubject<string> = new BehaviorSubject<string>("fr_FR");

  constructor(private translocoService:TranslocoService){}

  getLanguage(): Observable<any> {
    return this.currentLangage.asObservable();
  }

  setLanguage(value:string) {
    localStorage.setItem("Locale", value);    
    this.setLocales(value);
    this.translocoService.setActiveLang(value);
    this.currentLangage.next(value);
  }

  setLocales(value:string)
  {
        environment.Locale = value;
  }
}
